import React, { useEffect, useState } from 'react';
import './App.css';
import Login from './pages/login';
import ProductList from './pages/productList';
import ProductDialog from './pages/productDialog';
import ShoppingCard from './pages/shoppingCard';
import CheckoutForm from './admin/checkout';
import DetailToCheckout from './admin/detailtocheckout';
import Paymentgetway from './pages/paymentgetway';
import WatchTv from './about/watchtv';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import rootStore from './Redux/store';
import { HelmetProvider } from 'react-helmet-async';
import Aboutus from './about/aboutus';
import Privacy from './about/privacy';
import ContactUs from './about/contactus';
import Whatsapp from './admin/whatsapp';
import SellerForm from './admin/sellerform';
import Signin from './admin/signin';
import Offer from './pages/offer';
import Registration from './pages/registration';
import { generateToken, messaging } from './Notification/firebase';
import { onMessage } from 'firebase/messaging';
import toast, { Toaster } from 'react-hot-toast';
import Productpage from './pages/productpage';
import Main from './grocery/main';
import useOnlineStatus from './hooks/useOnlineStatus';
import GroceryList from './grocery/groceryList';
import GroceryDialog from './grocery/productdialog';
import OrganicProductpage from './grocery/productpage';

// store actions data and provided to the app
const store = createStore(
  rootStore,
  applyMiddleware(thunk)
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <ProductList />,
    children: [
      {
        path: "product/:main",
        element: <Offer />,
      },
      {
        path: "product/:main/:subproduct",
        element: <ProductDialog />,
      }
    ],
  },
  {
    path: "amazing+product/:item/:subproduct",
    element: <Productpage />,
  },
  {
    path: "product/shoppingCard",
    element: <ShoppingCard />
  },
  {
    path: "/login",
    element: <Registration />
  },
  {
    path: "/watchtv",
    element: <WatchTv />
  },
  {
    path: "/about",
    element: <Aboutus />
  },
  {
    path: "/privacy",
    element: <Privacy />
  },
  {
    path: "/contact",
    element: <ContactUs />
  },
  {
    path: "/paymentgetway",
    element: <Paymentgetway />,
    errorElement: <ErrorBoundry />
  },
  {
    path: '/signin',
    element: <Signin />
  },
  {
    path: "/savesoil",
    element: <Login />,
  },
  {
    path: "/checkout",
    element: <CheckoutForm />
  },
  {
    path: "/whatsapp",
    element: <Whatsapp />
  },
  {
    path: "/clientproducts",
    element: <SellerForm />
  },
  {
    path: "/detailtocheckout",
    element: <DetailToCheckout />
  },
  {
    path: "/grocery/products",
    element: <Main />
  },
  {
    path: "/list/",
    element: <GroceryList />,
    children: [
      {
        path: "grocery/:main",
        element: <Offer />,
      },
      {
        path: "grocery/:main/:subproduct",
        element: <GroceryDialog />,
      }
    ],
  },
  {
    path: "organic+grocery/:item/:subproduct",
    element: <OrganicProductpage />,
  },
]);

function ErrorBoundry() {
  return (
    <div className='flex flex-col justify-center items-center min-h-screen'>
      <h1 className='text-4xl font-bold'>Oops! Connection lost</h1>
      <p>
        Looks like you're back from payment process or you refresh the page
      </p>
      <a href="/product/all" className="mt-4 mb-8 w-1/2 rounded-md bg-gray-900 px-6 py-3 font-medium text-white">Home</a>
    </div>
  )
}
function App(props) {
  // const [useLocation, setUseLocation] = useState({})
  const isOnline = useOnlineStatus();

  useEffect(() => {
    generateToken()
    onMessage(messaging, (payload) => {
      // console.log(payload)
      toast(payload.notification.body)

      toast(payload.notification.body, {
        duration: 4000,
        position: 'top-center',

        // Styling
        style: {},
        className: '',

        // Custom Icon
        icon: '👏',

        // Change colors of success/error/loading icon
        iconTheme: {
          primary: '#000',
          secondary: '#fff',
        },

        // Aria
        ariaProps: {
          role: 'status',
          'aria-live': 'polite',
        },
      })
      // Toast(payload.notification.body)
      // alert(payload.notification.body)
    })
    // navigator.geolocation.getCurrentPosition((position) => {
    //   const { latitude, longitude } = position.coords
    //   setUseLocation({ latitude, longitude })
    //   if (parseInt(useLocation.latitude) == 18 && parseInt(useLocation.longitude) == 73) {
    //     toast("One Day Fastest Delivery Available", {
    //       duration: 8000,
    //       position: 'top-center',


    //       style: {},
    //       className: '',


    //       icon: '🏠🏍',

    //       iconTheme: {
    //         primary: '#000',
    //         secondary: '#fff',
    //       },


    //       ariaProps: {
    //         role: 'status',
    //         'aria-live': 'polite',
    //       },
    //     })
    //   }
    // },
    //   (error) => {
    //     console.log("error", error)
    //   })
  }, [])
  return (<HelmetProvider>
    {isOnline ? (
      <Provider store={store}>

        <div className="App">
          <Toaster />
          <RouterProvider router={router} {...props} />

        </div >
      </Provider >
    ) : (
      <div className='flex flex-col justify-center items-center min-h-screen'>
        <h1 className='text-4xl font-bold'>Oops! Connection lost</h1>
        <p>
          Looks like you're offline, please check your internet connection.
        </p>
      </div>
    )}
  </HelmetProvider >
  );
}

export default App;
