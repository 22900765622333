import React, { useCallback, useState, useEffect } from 'react'
// import { Dialog, Popover, Tab, Transition, Menu } from '@headlessui/react'
import ProductNav from './productNav'
import ProductDialog from './productDialog'
import ShoppingCard from './shoppingCard'
import { useSelector, useDispatch } from 'react-redux';
import { getProducts, AddtocardList, getProductsforDecore } from './../Redux/action'
import { Helmet } from 'react-helmet-async';
import {
    useParams, useNavigate
} from "react-router-dom";
// import { XMarkIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import axios from 'axios'
import { Fragment } from 'react'
// import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Logo from './../assist/triokzzlohgo.svg';
import Loadinglogo from './../assist/loadinglogo.png';
import OfferImg from '../assist/offeroffer.jpg';
import Hnadcrafted from '../assist/handicrafted_item.jpg';
import BackgroundFrame from '../assist/backgroundframe.jpg';
import Bagimg from '../assist/bag.jpg';
import PrintedMug from '../assist/PrintedMug.png';
import firebase from 'firebase/compat/app';
import "firebase/compat/messaging";
import { getMessaging, getToken } from "firebase/messaging";
import { analytics, messaging } from './../Notification/firebase.js'
import { onMessage } from 'firebase/messaging';
import toast, { Toaster, ToastBar } from 'react-hot-toast';
// import addNotification from 'react-push-notification';

// import Chatbot from './chatbot'
import FirstAd from '../assist/ad1.png';
import SecondAd from '../assist/ad2.png';
import ThirdAd from '../assist/ad3.png';
import ForthAd from '../assist/ad4.jpg';
import review1 from '../assist/review1.png';
import review2 from '../assist/review2.png';
import review3 from '../assist/review3.png';
import review4 from '../assist/review4.png';
import review5 from '../assist/review5.png';
import review6 from '../assist/review6.png';
import review7 from '../assist/review7.png';
import OfferBanner from '../assist/OfferBanner.jpg';
import './Celebration.css'
import OfferCard from './offer.js'


const Advertise = [

    { id: 0, imgSrc: "https://shopnowindia.s3.ap-south-1.amazonaws.com/organic_product/Triorg.png" },
    { id: 1, imgSrc: "https://shopnowindia.s3.ap-south-1.amazonaws.com/organic_product/Triorg.png" },
    { id: 2, imgSrc: "https://shopnowindia.s3.ap-south-1.amazonaws.com/organic_product/Triorg.png" },
    { id: 3, imgSrc: "https://shopnowindia.s3.ap-south-1.amazonaws.com/organic_product/Triorg.png" },
    { id: 4, imgSrc: Hnadcrafted },
    { id: 5, imgSrc: review5 },
    // { id: 6, imgSrc: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Clothing_store_banner.JPG/1920px-Clothing_store_banner.JPG" },
    { id: 7, imgSrc: "https://shopnowindia.s3.ap-south-1.amazonaws.com/organic_product/Triorg.png" },
    { id: 8, imgSrc: "https://shopnowindia.s3.ap-south-1.amazonaws.com/organic_product/Triorg.png" },
    { id: 9, imgSrc: "https://shopnowindia.s3.ap-south-1.amazonaws.com/organic_product/Triorg.png" },
    { id: 10, imgSrc: "https://shopnowindia.s3.ap-south-1.amazonaws.com/organic_product/Triorg.png" },
    { id: 11, imgSrc: "https://shopnowindia.s3.ap-south-1.amazonaws.com/organic_product/Triorg.png" },
]

const navigation = {
    categories: [
        {
            id: 'womens',
            name: 'Women',
            imgSrc: "https://www.svgrepo.com/show/493185/woman-holding-an-umbrella.svg"
        },
        {
            id: 'mens',
            name: 'Men',
            imgSrc: "https://www.svgrepo.com/show/492996/man-getting-dressed-in-front-of-mirror.svg"
        },
        {
            id: 'home decore',
            name: 'Home Decor',
            imgSrc: "https://www.svgrepo.com/show/282740/frame.svg"
        },
        // {
        //     id: 'medical equipment',
        //     name: 'Medical equipment',
        //     imgSrc: "https://www.svgrepo.com/show/61697/medical-kit.svg"
        // },
        {
            id: 'watchtv',
            name: 'WatchTv',
            imgSrc: "https://www.svgrepo.com/show/7377/television.svg"
        },
    ],
    pages: [
        { name: 'WatchTv', href: 'watchtv' }
    ],
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const ProductList = (props) => {
    const { main, subproduct } = useParams()
    const [subNavItem, setSubNavItem] = useState(subproduct)
    const [dialog, setDialog] = useState(false)
    const [cardProduct, setCardProduct] = useState({})
    const [addtocard, setAddtocard] = useState([])
    const [openshopping, setOpenShopping] = useState(false)
    const [navItem, setNavItem] = useState(main || "all")
    const [serchableoutput, setserchableoutput] = useState([])
    const [menuItems, setMenuItems] = useState([])
    const [menuproduct, setMenuProduct] = useState([])
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // console.log(menuItems)

    let schemajsonmen = {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": "Plain T-shirt",
        "image": [
            "https://shopnowindia.s3.ap-south-1.amazonaws.com/menwomen_teeshirts_unisex/JT-W-69-74.png",
            "https://shopnowindia.s3.ap-south-1.amazonaws.com/menwomen_teeshirts_unisex/JI-W-105-1099.png",
            "https://shopnowindia.s3.ap-south-1.amazonaws.com/menwomen_teeshirts_unisex/JI-W-80-84.png",
            "https://shopnowindia.s3.ap-south-1.amazonaws.com/menwomen_teeshirts_unisex/JT-W-100-104.png",
            "https://shopnowindia.s3.ap-south-1.amazonaws.com/menwomen_teeshirts_unisex/mint-JI-W-150-154.png",
            "https://shopnowindia.s3.ap-south-1.amazonaws.com/menwomen_teeshirts_unisex/JI-W-115-119.png",
            "https://shopnowindia.s3.ap-south-1.amazonaws.com/menwomen_teeshirts_unisex/JI-T-110-114.png",
            "https://shopnowindia.s3.ap-south-1.amazonaws.com/menwomen_teeshirts_unisex/JI-T-13-17.png"
        ],
        "description": "Ring spun super combed cotton single jersey knit, Only heaters colors are 80% cotton 12 % polyster,Bio Washed/ Color Fastness Gauranteed.Its durable,anti-pilling,breathable,soft feel.",
        "sku": "1122334455",
        "mpn": "111111",
        "brand": {
            "@type": "JiGari",
            "name": "Plain T-shirt"
        },
        "review": {
            "@type": "Review",
            "reviewRating": {
                "@type": "Rating",
                "ratingValue": 4,
                "bestRating": 5
            },
            "author": {
                "@type": "Person",
                "name": "Kunal Khadke"
            }
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": 4.4,
            "reviewCount": 117
        },
        "offers": {
            "@type": "Offer",
            "url": "https://www.triokzz.com/product/mens",
            "itemCondition": "https://schema.org/NewCondition",
            "availability": "https://schema.org/InStock",
            "price": 299.0,
            "priceCurrency": "INR",
            "priceValidUntil": "2025-11-20"
        }
    }
    //selecting category of product from navbar
    const onSelectNavItem = (item) => {
        if (item == "watchtv") {
            navigate(`/watchtv`)
        } else {
            setNavItem(item)
        }

        // navigate(`/${item}`)
    }

    // get data from redux
    let TrendingProducts = []
    TrendingProducts = useSelector(state => state.allProducts.user)

    let GetDecoreProduct = []
    GetDecoreProduct = useSelector(state => state.allProducts.decoreuser)

    // dispatch action 
    const scrolltobottom = () => {
        window.scrollTo(0, 1000)
    }
    useEffect(() => {
        // if (navItem !== "mens" && navItem !== "womens" && navItem !== "home decore" && navItem !== "printed" && navItem !== "travelling") {

        //     dispatch(getProducts("all"))
        //     navigate(`/product/all`)


        // } else {
        dispatch(getProducts(navItem, setMenuItems))
        navigate(`/product/${navItem}`)
        // }
        // scrolltobottom()
    }, [navItem])

    //product of grocery items
    const getGroceryProducts = async (navItem) => {

        let TrendingProducts = await axios.get("https://kqej90wmli.execute-api.ap-south-1.amazonaws.com/dev/organic_products")
            .then(r => r)
            .then(res => res.data)
            .catch(err => console.log(err))
        setMenuProduct(TrendingProducts)
    };
    useEffect(() => {
        getGroceryProducts("Millets")
    }, [])


    const onshoppingRender = useCallback(() => {
        // navigate("product/shoppingCard")
        return setOpenShopping(true)
    }, [])

    const onOpen = (product, shopping_card_id) => {

        let somthing = addtocard && addtocard.find((val) => {
            // console.log(val)
            return val.id == product.id
        })
        if (!product.freez && somthing == undefined) {
            setDialog(!dialog)
            setCardProduct(product)
            console.log("render", shopping_card_id)
            // navigate(`/product/${navItem}/${product.type} ${product.color}`)
            // addNotification({
            //     title: 'New Arrivals',
            //     subtitle: 'Shop on triokzz',
            //     message: 'Get amazing travelling bags and  handicraft items only on triokzz',
            //     theme: 'darkblue',
            //     // duration: 60000 * 10,
            //     backgroundTop: 'green', //optional, background color of top container.
            //     backgroundBottom: 'darkgreen', //optional, background color of bottom container.
            //     colorTop: 'green',
            //     native: true, // when using native, your OS will handle theming.
            //     onClick: (e) => window.open("http://www.triokzz.com", "_blank")
            // });
        } else {


            if (shopping_card_id >= 0) {
                // console.log("render", product)
                const forSizechange = {
                    brand: product && product.name,
                    color: product && product.colors && product.colors.name,
                    count: 1,
                    detail_url: product && product.detail_url,
                    freez: product && product.freez,
                    id: product && product.id,
                    offer_prize: product && product.price,
                    product_detail: product && product.detail,
                    product_name: product && product.product_name,
                    quantity: "10",
                    rating: product && product.rating,
                    real_prize: product && product.real_prize,
                    size: product && product.size,
                    type: product && product.type,
                    uni_productId: 1317,
                    url: product && product.imageSrc,
                    delivery_charge: product && product.delivery_charge
                }
                setCardProduct(forSizechange)
                setTimeout(() => {
                    setDialog(!dialog)
                })
            } else {
                onshoppingRender()
            }
        }
    }


    const onfilter = async (serchinput, dropdownsearch) => {
        // console.log("asasa")
        let searchableoutput = []
        let serching
        if (serchinput !== undefined) {
            serching = serchinput && serchinput.toLowerCase()
        } else {
            serching = ""
            setserchableoutput([])
        }

        if (serching && serching.length > 2) {
            let TrendingProductsforsearch = await axios.get("https://kqej90wmli.execute-api.ap-south-1.amazonaws.com/dev/products")
                .then(r => r)
                .then(res => res.data)
                .catch(err => toast("something went wrong"))


            let productaction = TrendingProductsforsearch && TrendingProductsforsearch.products && TrendingProductsforsearch.products.find((val, i, arr) => {
                return val.product_for == dropdownsearch
            })

            productaction && productaction.section && productaction.section.map((value, index) => {
                let [keys] = Object.keys(value)
                let arr = value[keys]
                let hello = arr.map(val => val)

                const filteredval = hello.filter(info => {

                    return info && info.product_detail && info.product_detail.toLowerCase() && info.product_detail.toLowerCase().includes(serching) || info && info.product_name && info.product_name.toLowerCase() && info.product_name.toLowerCase().includes(serching)
                }
                )

                if (filteredval.length > 0) {
                    setserchableoutput(filteredval)
                }

                return filteredval
            })

        }

    }




    // added items
    const onAddToCard = (cardproducts) => {
        let somthing = addtocard && addtocard.find((val) => {
            // console.log(val)
            return val.id == cardproducts.id
        })
        // console.log(somthing)
        if (somthing) {
            let filtereditem = addtocard && addtocard.filter((val) => {
                // console.log(val)
                return val.id !== cardproducts.id
            })
            let items = filtereditem.concat(cardproducts)
            setAddtocard(items)
            localStorage.setItem("addtocard", JSON.stringify(items))
            dispatch(AddtocardList(items))
            onshoppingRender()
        } else
            if (somthing == undefined) {
                let items = addtocard && addtocard.concat(cardproducts)
                setAddtocard(items)
                localStorage.setItem("addtocard", JSON.stringify(items))
                dispatch(AddtocardList(items))
                onshoppingRender()
            } else {
                onshoppingRender()
            }
        analytics.logEvent('add_to_cart')
        const message = {
            notification: {
                title: 'ms.dipali just buyed organic products',
                body: 'Go organic. Buy with exclusive offer on each an every collection of organic items.'
            },
            webpush: {
                fcm_options: {
                    link: "https://triokzz.com"
                }
            }
        };
        toast(
            <span>
                <b>added to cart</b>
            </span>,
            {
                icon: '🔥',
            }
        )
    }

    // qantity of the product
    const onCount = (e, id, index) => {
        var a = addtocard && addtocard.find((val, i) => val.id == id)
        // console.log(a.id, id)
        if (a.id == id) {
            addtocard[index].count = e.target.value
            localStorage.setItem("addtocard", JSON.stringify(addtocard))
        }

    }

    const onRemove = (item, index) => {
        const removeItem = addtocard && addtocard.filter((value, i) => {
            return i !== index
        })
        setAddtocard(removeItem)
        localStorage.setItem("addtocard", JSON.stringify(removeItem))
        dispatch(AddtocardList(removeItem))
    }

    useEffect(() => {
        const getitem = localStorage.getItem("addtocard")
        if (getitem != null) {
            setAddtocard(JSON.parse(getitem))
            dispatch(AddtocardList(JSON.parse(getitem)))
        }

    }, [])
    useEffect(() => {
        setTimeout(() => {
            sessionStorage.setItem("addtocard", JSON.stringify(addtocard))
        }, 100)

    }, [addtocard])


    //loading effect 
    // if (!TrendingProducts) {
    //     return (<div className="bg-white justify-center ">
    //         <img src={Loadinglogo} alt="loading" title="triokzz" className="h-screen w-screen" />
    //     </div>
    //     )
    // }

    return (
        <div className="bg-white justify-center">
            <Helmet>

                {/* {navItem == "home decore" ? <title>Home Decor: Huge Selection Of Handicraft Products For Bharat 🛒</title> :
                    <title>Solid Tshirt,Printed Mugs,Bags and More🛒</title>} */}
                {navItem == "home decore" ? <title>Buy Home Decor Products Online</title> :
                    navItem == "womens" ? <title>Buy Plain T-shirts for Womens Online</title> :
                        navItem == "printed" ? <title>Buy Printed Mugs Gift Collection Online</title> :
                            navItem == "travelling" ? <title>Buy Travel Handbags, Purse & Acessories Collection</title> :
                                navItem == "mens" ? <title>Buy Plain T-shirt for mens Online</title> : ""}


                {navItem == "home decore" ? <meta name="description"
                    content="Amazing Home Decor Items.Best Hand Made Decor Items,Temple for your home, Bamboos Products on TRIOKZZ">
                </meta> :
                    navItem == "womens" ? <meta name="description"
                        content="Buy Women Solid Plain T-shirts Sale. Now Experience ultimate comfort and stylish plain solid T-shirt with our Essential Comfort Unisex Plain T-Shirt">
                    </meta> :
                        navItem == "printed" ? <meta name="description"
                            content="Big Online Sale, Gift printed mug art of printo with our Artistic Expression. premium ceramic mug on Triokzz">
                        </meta> :
                            navItem == "travelling" ? <meta name="description"
                                content="Buy online Handbags, Purse and Travel bags fasionable Purses Collection. Big Sale Introducing the Wanderlust Fabric Travel Cloth Bag, your perfect eco-friendly travel companion.">
                            </meta> :
                                navItem == "mens" ? <meta name="description"
                                    content="Buy Mens Women Solid Plain T-shirts. Online Shopping for mens. Buy latest fashion from wide range of mens product. Now Save more on Total shopping. Coupan code Triokzz11">
                                </meta> : <meta name="description"
                                    content={`${navItem} Product at one place. Yes, Big Sale- Flat Off On Each  ${navItem} Products. Free Shipping on each an every exclusive collection at triokzz.Stylish ${navItem} products upto 50% off`} />}
                <meta name="robots" content="index, follow" />
                <meta name="robots" content="max-image-preview:large" />
                {/* <meta name="title" content="Triokzz-Your online shop"></meta> */}
                <meta name="keywords" content="Purse, Mug, fashion, club, mees, Big Sale, Flat Off, Black Friday, Plain T-shirts, Printed mugs, Travel Bags, Duffle, Solid Tshirt, Bewakoofy items, home decor items, sculptures, inspiring, large luggage bags, popular duffle, touring bags, big size travel bags online, purse, wholesale mugs, custom cups and mugs, ceramic printed mugs, copper drinking mugs."></meta>
                {navItem == "Home Decor & Temple" ? <link rel="canonical" href="https://www.triokzz.com/product/Home%20Decor%20&%20Temple" /> : navItem == "womens" ? <link rel="canonical" href={`https://www.triokzz.com/product/womens`} /> : navItem == "printed" ? <link rel="canonical" href={`https://www.triokzz.com/product/printed`} /> : navItem == "travelling" ? <link rel="canonical" href={`https://www.triokzz.com/product/travelling`} /> : navItem == "mens" ? <link rel="canonical" href={`https://www.triokzz.com/product/mens`} /> : <link rel="canonical" href={`https://www.triokzz.com/product/all`} />}
                <meta name="robots" content="index, follow" />
                <meta name="robots" content="max-image-preview:large" />
                <meta name="google-site-verification" content="vwUAwcHi4pY-Lgm6a9JJyOwn1GLbkA7gO6fvQOy4FvU" />
                <meta property="og:type" content="website" />
                {navItem == "home decore" ? <meta property="og:title" content="Buy Home Decor: Decorate your home with our small diy decor item 🛒" /> :
                    navItem == "womens" ? <meta property="og:title" content="Women's Unprinted Tshirts: Buy Basic T-shirts Simple Tees🛒" /> :
                        navItem == "printed" ? <meta property="og:title" content="Buy Unique Mugs: We have printed Design mugs for you🛒" /> :
                            navItem == "travelling" ? <meta property="og:title" content="Womens Crossbody Leather Fashion Purses, Travel Bags" /> :
                                navItem == "mens" ? <meta property="og:title" content="Mens Basic Tshirts: Buy Unprinted T-shirts Simple Tees🛒" /> : ""}

                {navItem == "home decore" ? <meta property="og:description" content="Buy Online:Handicraft Home Decor and more.Save your amazing power looks items and flip to cart now 🛒" /> :
                    navItem == "womens" ? <meta property="og:description" content="Buy Women's Online:Amazing power looks T-shirts and flip to cart now.Womens Crossbody Leather Fashion Purses, Travel Bags.Amazing power looks your outlook with T-shirts, this may mesh to flip your choice now :)🛒🛒" /> :
                        navItem == "printed" ? <meta property="og:description" content="Buy Printed Mugs Online: We have printed Design mugs for you.You can call it beer mug, coffee Mug or tea mug Save your amazing power looks items and flip to cart now 🛒" /> :
                            navItem == "travelling" ? <meta property="og:description" content="Buy Travel Bags Online: Trendy Fashion Purse, artistic rounded Purse, duffle bags available only on TRIOKZZ🛒" /> :
                                navItem == "mens" ? <meta property="og:description" content="Buy Mens Basic Tshirts Solid Tees Unprinted T-shirts Simple Tees only on Triokzz.Amazing power looks your outlook with T-shirts, this may mesh to flip your choice now :)🛒" /> : ""}

                {navItem == "home decore" ? <meta property="og:image" content={PrintedMug} /> :
                    navItem == "womens" ? <meta property="og:image" content={review5} /> :
                        navItem == "printed" ? <meta property="og:image" content={review6} /> :
                            navItem == "travelling" ? <meta property="og:image" content={review7} /> :
                                navItem == "mens" ? <meta property="og:image" content={review1} /> : <meta property="og:image" content={review7} />}
                <meta property="og:image:height" content="1200" />
                <meta property="og:image:width" content="630" />

                {navItem == "home decore" ? <meta property="og:url" content="https://www.triokzz.com/product/Home%20Decor%20&%20Temple" /> :
                    navItem == "womens" ? <meta property="og:url" content="https://www.triokzz.com/product/womens" /> :
                        navItem == "printed" ? <meta property="og:url" content="https://www.triokzz.com/product/printed" /> :
                            navItem == "travelling" ? <meta property="og:url" content="https://www.triokzz.com/product/travelling" /> :
                                navItem == "mens" ? <meta property="og:url" content="https://www.triokzz.com/product/mens" /> : ""}
                {navItem == "home decore" ? <meta property="og:site_name" content="Shop home decor With Trusted By The World’s Most Innovative Teams" /> :
                    navItem == "womens" ? <meta property="og:site_name" content="Shop Plain Tees With Trusted By The World’s Most Innovative Teams" /> :
                        navItem == "printed" ? <meta property="og:site_name" content="Shop printed mugs cup With Trusted By The World’s Most Innovative Teams" /> :
                            navItem == "travelling" ? <meta property="og:site_name" content="Shop Travel Bags Online" /> :
                                navItem == "mens" ? <meta property="og:site_name" content="Plain Tshirts Online" /> : <meta property="og:site_name" content="Triokzz" />}
                <meta property="og:image:alt" content="Images T-shirt Mugs Travel Bags Mouse Pad" />
                <meta property="og:image:type" content="image/*" />
                {/* <meta property="og:site_name" content="Namashkaram, Shop Online With Trusted By The World’s Most Innovative Teams" /> */}
                <script type="application/ld+json">
                    {`
      {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": "Round Sling Bags",
        "alternateName":["Rounded Purse", "Duffle", "T-KZ", "Bags", "Handbags", "Purse"],
        "image": [   "https://shopnowindia.s3.ap-south-1.amazonaws.com/Triokzz+Bags/brown+multicolor+purse/Brown+with+Multicolour+Purse_(2).png",
          "https://shopnowindia.s3.ap-south-1.amazonaws.com/Triokzz+Bags/brown+white+multicolor+purse/Brown+with+White+Colour+Purse_(2).png",
          "https://shopnowindia.s3.ap-south-1.amazonaws.com/Triokzz+Bags/brown+white+multicolor+purse/Brown+with+White+Colour+Purse_.png",
          "https://shopnowindia.s3.ap-south-1.amazonaws.com/Triokzz+Bags/brown+multicolor+purse/Brown+with+Multicolour+Purse_.png"
         ],
        "description": "This round Sling purse bag is your perfect partner for all your adventures! It is a bag with a gold-toned clasp and a tassel.Hand bag approximate 250 gm with 3 pockets.",
        "sku": "Purse-201",
        "mpn": "119211",
        "brand": {
          "@type": "Brand",
          "name": "Triokzz"
        },
        "review": {
          "@type": "Review",
          "reviewRating": {
            "@type": "Rating",
            "ratingValue": 4,
            "bestRating": 5
          },
          "author": {
            "@type": "Person",
            "name": "Pritam Maniyar"
          }
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": 4.4,
          "reviewCount": 3111
        },
        "offers": {
          "@type": "Offer",
          "url": "https://www.triokzz.com/product/travelling",
          "itemCondition": "https://schema.org/NewCondition",
          "availability": "https://schema.org/InStock",
          "price": 360.0,
          "priceCurrency": "INR",
          "priceValidUntil": "2025-11-20",
          "shippingDetails": {
            "@type": "OfferShippingDetails",
            "shippingRate": {
              "@type": "MonetaryAmount",
              "value": 0.0,
              "currency": "INR"
            },
            "shippingDestination": {
              "@type": "DefinedRegion",
              "addressCountry": "IN"
            },
            "deliveryTime": {
              "@type": "ShippingDeliveryTime",
              "handlingTime": {
                "@type": "QuantitativeValue",
                "minValue": 0,
                "maxValue": 1,
                "unitCode": "DAY"
              },
              "transitTime": {
                "@type": "QuantitativeValue",
                "minValue": 1,
                "maxValue": 5,
                "unitCode": "DAY"
              }
            }
          },
          "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "applicableCountry": "IN",
            "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",
            "merchantReturnDays": 30,
            "returnMethod": "https://schema.org/ReturnByMail",
            "returnFees": "https://schema.org/FreeReturn"
          }
        }
      }
    `}
                </script>

                <script type="application/ld+json">
                    {`
      {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": "Women Solid T-shirt",
        "alternateName":["Unisex Tshirt", "T-KZ", "Tees", "Plain T-shirt", "t shirt"],
        "image": [
          "https://shopnowindia.s3.ap-south-1.amazonaws.com/menwomen_teeshirts_unisex/JT-W-69-74.png",
          "https://shopnowindia.s3.ap-south-1.amazonaws.com/menwomen_teeshirts_unisex/JI-W-105-1099.png",
          "https://shopnowindia.s3.ap-south-1.amazonaws.com/menwomen_teeshirts_unisex/JT-W-100-104.png",
          "https://shopnowindia.s3.ap-south-1.amazonaws.com/menwomen_teeshirts_unisex/pale-yello-JI-W-160-164.png"
        ],
        "description": "Buy Mens Women Solid T-shirts Sale Live On Plain T-shirt Now Experience ultimate comfort and stylish plain solid T-shirt with our Essential Comfort Unisex Plain T-Shirt. Made from soft, breathable fabric, this t-shirt is a must-have staple for any wardrobe",
        "sku": "JI-W-80-84",
        "mpn": "119311",
        "brand": {
          "@type": "Brand",
          "name": "JiGari"
        },
        "review": {
          "@type": "Review",
          "reviewRating": {
            "@type": "Rating",
            "ratingValue": 4,
            "bestRating": 5
          },
          "author": {
            "@type": "Person",
            "name": "Pritam Maniyar"
          }
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": 4.4,
          "reviewCount": 3111
        },
        "offers": {
          "@type": "Offer",
          "url": "https://www.triokzz.com/product/womens",
          "itemCondition": "https://schema.org/NewCondition",
          "availability": "https://schema.org/InStock",
          "price": 299.0,
          "priceCurrency": "INR",
          "priceValidUntil": "2025-11-20",
          "shippingDetails": {
            "@type": "OfferShippingDetails",
            "shippingRate": {
              "@type": "MonetaryAmount",
              "value": 0.0,
              "currency": "INR"
            },
            "shippingDestination": {
              "@type": "DefinedRegion",
              "addressCountry": "IN"
            },
            "deliveryTime": {
              "@type": "ShippingDeliveryTime",
              "handlingTime": {
                "@type": "QuantitativeValue",
                "minValue": 0,
                "maxValue": 1,
                "unitCode": "DAY"
              },
              "transitTime": {
                "@type": "QuantitativeValue",
                "minValue": 1,
                "maxValue": 5,
                "unitCode": "DAY"
              }
            }
          },
          "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "applicableCountry": "IN",
            "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",
            "merchantReturnDays": 30,
            "returnMethod": "https://schema.org/ReturnByMail",
            "returnFees": "https://schema.org/FreeReturn"
          }
        }
      }
    `}
                </script>
                {/* <script type="application/ld+json">
                    {JSON.stringify(schemajsonmen)}
                </script> */}
            </Helmet >
            <ProductNav {...props} onSelectNavItem={onSelectNavItem} onfilter={onfilter} onshoppingRender={onshoppingRender} selectedItem={addtocard && addtocard.length} menuItems={menuItems} />

            <div className="flex  overflow-y-scroll pb-2 hide-scroll-bar rounded-lg">
                <ul className="flex flex-nowrap">
                    {menuItems && menuItems.products && menuItems.products.map((val, i) => {
                        return (<li
                            className="bg-lime-100  p-1 flex items-center flex-col  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-1 py-1 text-center me-2 m-2"
                        >
                            <a href={`/product/${val.product_for}`}>
                                <img src={val.image ? val.image : "https://shopnowindia.s3.ap-south-1.amazonaws.com/organic_product/Triorg.png"}
                                    alt="Trendy Products"
                                    className="ml-4 rounded-full w-16 h-16 object-cover"
                                    title="Decorate your home with triokzz"
                                />
                                <h5 className="capitalize truncate text-sm w-24 text-sky-600">{val.product_for}</h5>
                            </a>
                        </li>)
                    }
                    )
                    }
                </ul>
            </div>


            <OfferCard navItem={navItem} />
            <hr className="border-b-2 rounded-md drop-shadow-lg"></hr>
            <div className="scroll-left flex bg-blue-900">
                <header>
                    <h1 className="flex capitalize text-lg font-medium text-white">Fastest Delivery Network. Best Selective {navItem} Products Trusted By The World’s Most Innovative Teams. Product partner- JiGari, Kalanshuk textile studio, Bhavna Art,PriyankaFarm, Manas Jaivik Mart </h1>

                </header>
            </div>

            <div className="relative mx-auto max-w-2xl px-2 py-2 sm:px-6 sm:py-6 lg:max-w-7xl lg:px-8  " >
                <div className="mt-2 mr-2 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 xl:gap-x-2">
                    {serchableoutput.length > 0 ? serchableoutput.map((val, id) => {

                        return (
                            <div key={val.uni_productId} className={val.freez ? "group relative cursor-not-allowed transformed opacity-25 bg-white flex" : "group relative bg-white flex"} onClick={() => onOpen(val)}>

                                <div className="aspect-h-1 aspect-w-1 w-48 overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-48">
                                    <img
                                        src={val.url}
                                        alt={val.type}
                                        style={{ height: "190px", width: "100%", objectFit: "fill" }}
                                        className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                                        title={val.product_name}
                                    />
                                    {val.freez && <span class="before:block before:absolute before:-inset-1 before:-skew-y-3 before:bg-green-500 relative inline-block">
                                        <span class="relative text-white">Sold out</span>
                                    </span>}
                                </div>
                                <div className="mt-8 justify-between w-48">
                                    <div>
                                        <h3 className="text-md text-gray-700">
                                            <span aria-hidden="true" className="absolute inset-0" />
                                            {val.brand}
                                        </h3>
                                        <p className="mt-1 text-md text-gray-500">{val.color}</p>
                                    </div>

                                    <div>
                                        <h3 className="text-sm mt-4 font-medium text-green"><span className="text-md text-gray-700">Offer Price:</span>{val.offer_prize}</h3>
                                        <p className="text-sm font-medium text-red-900 line-through"><span className="text-sm text-gray-700">Real Price:</span>{val.real_prize}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }) : <div className="relative  grid grid-cols-1 w-full" >
                        <div className="bg-white py-2 sm:py-2 sr-only">
                            <div className="mx-auto max-w-7xl px-2 lg:px-8">

                                <h2 className="text-center text-md font-semibold leading-8 text-gray-900 ">
                                    <a href="https://triokzz.com/product/watchtv">Luxury is not something vain, somebody went through a lot of effort is put in {navItem}. Products Trusted By The World’s Most Innovative Teams</a>
                                </h2>
                            </div>
                        </div>

                        <div className="relative w-full">
                            {/* GetDecoreProduct variable to get product to scroll from db */}
                            {/* <p className="ml-2 capitalize text-lg font-medium font-semibold dark:text-white ">𝔸𝕊𝕊𝕆ℂ𝕀𝔸𝕋𝔼 Partner Of {navItem} Products</p> */}
                            {/* <Swiper
                                spaceBetween={2}
                                slidesPerView={2}
                                modules={[Autoplay]}

                                autoplay={{
                                    delay: 20000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{ clickable: true }}

                            >

                                {
                                    GetDecoreProduct ? GetDecoreProduct.section.map((specialProd, i) => {
                                        let [keys] = Object.keys(specialProd)
                                        let arr = specialProd[keys]
                                        return (<div className="swiper-wrapper">
                                            {arr.map((val, i) => {
                                                return (
                                                    <SwiperSlide >


                                                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 item-center justify-items-center ">
                                                            <div class="col-span-2 sm:col-span-1 md:col-span-2 bg-stone-50">
                                                                <article class="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl px-4 pb-2 pt-20 max-w-sm mx-auto">
                                                                    <img src={val.url} alt={val.type} title={val.product_name} class="absolute inset-0 h-full w-full object-cover" />
                                                                    <div class="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/40"></div>
                                                                    <h3 class="z-10 mt-3 text-3xl font-bold text-white">{val.brand}</h3>
                                                                </article>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                )
                                            })}
                                        </div>)
                                    }) : <div role="status" class="max-w-sm p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700">
                                        <div class="flex items-center justify-center h-24 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                                            <svg class="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
                                                <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                                                <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                                            </svg>
                                        </div>
                                        <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                                        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                                        <div class="flex items-center mt-4">
                                            <svg class="w-10 h-10 me-3 text-gray-200 dark:text-gray-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                                            </svg>
                                            <div>
                                                <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                                                <div class="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                                            </div>
                                        </div>
                                        <span class="sr-only">Loading...</span>
                                    </div>}

                            </Swiper> */}
                            <div className="flex  overflow-y-scroll pb-2 hide-scroll-bar rounded-lg">
                                <div className="flex flex-nowrap" onClick={() => navigate('grocery/products')}>
                                    {menuproduct && menuproduct.products && menuproduct.products.map((val, i) => {
                                        return (
                                            <li
                                                className="p-1 flex items-center flex-col  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-1 py-1 text-center me-2 m-2"
                                            >

                                                <img src={val.image ? val.image : "https://shopnowindia.s3.ap-south-1.amazonaws.com/organic_product/Triorg.png"}
                                                    alt="Bhartiya jaivik organic Products test of india"
                                                    className="rounded-md w-16 h-16 object-cover"
                                                    title="Decorate your home with triokzz"
                                                />
                                                <h5 className="capitalize truncate text-sm w-24 text-sky-600">{val.product_for}</h5>

                                            </li>
                                        )
                                    })}
                                </div>
                            </div>

                        </div>
                    </div>
                    }
                </div>
            </div>
            <section class="py-5 relative">
                <div class="w-full max-w-7xl mx-auto px-4 md:px-8">
                    <div class="grid grid-cols-10 gap-8">

                        <div class="col-span-10 lg:col-span-6 w-full">
                            <div class="container mx-auto">
                                <h5 class="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-rose-600 via-red-400 to-orange-500"><span className="font-culpa">Festival</span> Offer's</h5>
                            </div>
                            <div class="box flex h-full justify-center flex-col">
                                <div class="grid grid-cols-4 sm:grid-cols-5 w-full">
                                    <div class="box flex flex-col items-center">
                                        <button
                                            class="p-3 rounded-full shadow-sm shadow-transparent bg-indigo-50 flex items-center justify-center transition-all duration-500 hover:bg-indigo-100 hover:shadow-indigo-200 focus-within:bg-indigo-100 focus-within:outline-0">
                                            <svg width="37" height="36" viewBox="0 0 37 36" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M3.5 4.5H4.45C6.24134 4.5 7.13701 4.5 7.6935 5.0565C8.25 5.61299 8.25 6.50866 8.25 8.3C8.25 11.3667 8.25 14.4333 8.25 17.5C8.25 20.3284 8.25 21.7426 9.12868 22.6213C10.0074 23.5 11.4216 23.5 14.25 23.5C20.8 23.5 27.35 23.5 33.9 23.5M13.95 30.15C13.95 31.724 12.674 33 11.1 33C9.52599 33 8.25 31.724 8.25 30.15C8.25 28.576 9.52599 27.3 11.1 27.3C12.674 27.3 13.95 28.576 13.95 30.15ZM32.95 30.15C32.95 31.724 31.674 33 30.1 33C28.526 33 27.25 31.724 27.25 30.15C27.25 28.576 28.526 27.3 30.1 27.3C31.674 27.3 32.95 28.576 32.95 30.15ZM28.6 5.85C24.9 5.85 21.2 5.85 17.5 5.85C16.0858 5.85 15.3787 5.85 14.9393 6.28934C14.5 6.72868 14.5 7.43579 14.5 8.85C14.5 10.65 14.5 12.45 14.5 14.25C14.5 15.6642 14.5 16.3713 14.9393 16.8107C15.3787 17.25 16.0858 17.25 17.5 17.25C21.2 17.25 24.9 17.25 28.6 17.25C30.0142 17.25 30.7213 17.25 31.1607 16.8107C31.6 16.3713 31.6 15.6642 31.6 14.25C31.6 12.45 31.6 10.65 31.6 8.85C31.6 7.43579 31.6 6.72868 31.1607 6.28934C30.7213 5.85 30.0142 5.85 28.6 5.85Z"
                                                    stroke="#4F46E5" stroke-width="2.5" stroke-linecap="round" />
                                            </svg>
                                        </button>
                                        <p class="mt-2 w-[92px] text-center font-medium text-sm text-black">Free delivery over rs 1449</p>
                                    </div>
                                    <div class="box flex flex-col items-center">
                                        <button class="rounded-full p-3 flex items-center justify-center mx-auto bg-white shadow-sm shadow-transparent transition-all duration-500 hover:bg-indigo-50 hover:shadow-indigo-200">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37" fill="none">
                                                <path d="M8.88892 7.54889C13.6781 2.15037 22.3219 2.15037 27.1111 7.54889C30.963 11.8909 30.963 18.2981 27.1111 22.6401L19.8935 30.776C19.3805 31.3543 19.1241 31.6434 18.8418 31.7923C18.3172 32.0692 17.6828 32.0692 17.1582 31.7923C16.8759 31.6434 16.6195 31.3543 16.1065 30.776L8.88892 22.6401C5.03703 18.2981 5.03703 11.8909 8.88892 7.54889Z" stroke="#4F46E5" stroke-width="1.6" />
                                                <path d="M21.5122 14.2461C21.5122 16.1203 19.9397 17.6396 18 17.6396C16.0603 17.6396 14.4878 16.1203 14.4878 14.2461C14.4878 12.3719 16.0603 10.8526 18 10.8526C19.9397 10.8526 21.5122 12.3719 21.5122 14.2461Z" stroke="#4F46E5" stroke-width="1.6" />
                                            </svg>
                                        </button>
                                        <p class="mt-2 w-[92px] text-center font-medium text-sm text-black"> Pan India delivery</p>
                                    </div>
                                    <div class="box flex flex-col items-center">
                                        <button class="rounded-full p-3 flex items-center justify-center mx-auto bg-white shadow-sm shadow-transparent transition-all duration-500 hover:bg-indigo-50 hover:shadow-indigo-200">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37" fill="none">
                                                <path d="M25.5 32L30.4923 24.5115C30.9925 23.7613 31.2426 23.3862 31.3713 22.961C31.5 22.5359 31.5 22.085 31.5 21.1833V14.75C31.5 13.5074 30.4926 12.5 29.25 12.5C28.0074 12.5 27 13.5074 27 14.75V19.5C27 20.4912 27 20.9868 26.846 21.4487C26.6921 21.9105 26.3947 22.307 25.8 23.1L24.75 24.5M10.5 32L5.5077 24.5115C5.00753 23.7613 4.75745 23.3862 4.62872 22.961C4.5 22.5359 4.5 22.085 4.5 21.1833V14.75C4.5 13.5074 5.50736 12.5 6.75 12.5C7.99264 12.5 9 13.5074 9 14.75V19.5C9 20.4912 9 20.9868 9.15395 21.4487C9.3079 21.9105 9.60527 22.307 10.2 23.1L11.25 24.5M21 32V26.1847C21 24.0551 21 22.9903 21.4479 22.2655C21.8244 21.656 22.4066 21.2015 23.0892 20.984C23.901 20.7253 24.184 20.9833 26.25 21.4998M15 32V26.1847C15 24.0551 15 22.9903 14.5521 22.2654C14.1756 21.656 13.5934 21.2014 12.9108 20.9839C12.099 20.7252 11.816 20.9833 9.75 21.4998M13.2744 11.1311L18.0141 15.8006L22.9612 10.9268M18 6.01298C19.3709 4.66234 21.5987 4.66234 22.9697 6.01298C24.3406 7.36806 24.3406 9.5584 22.9742 10.9135M17.9979 6.01298C16.6269 4.66234 14.3992 4.66234 13.0282 6.01298C11.6573 7.36361 11.6573 9.55839 13.0282 10.909M13.0282 10.909L13.0398 10.9204M13.0282 10.909L13.2717 11.1267" stroke="#4F46E5" stroke-width="1.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </button>
                                        <p class="mt-2 w-[92px] text-center font-medium text-sm text-black"> Real-Time Offers</p>
                                    </div>

                                    <div class="box flex flex-col items-center">
                                        <button class="rounded-full p-3 flex items-center justify-center mx-auto bg-white shadow-sm shadow-transparent transition-all duration-500 hover:bg-indigo-50 hover:shadow-indigo-200">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37" fill="none">
                                                <path d="M27 5V9.71405M27 5H21.9785M27 5L15.0389 15.6321C14.5876 16.0332 14.362 16.2338 14.1198 16.3311C13.7326 16.4867 13.298 16.4739 12.9205 16.2958C12.6845 16.1845 12.471 15.971 12.0441 15.5441C11.5939 15.0939 11.3688 14.8688 11.1218 14.7563C10.7268 14.5763 10.2732 14.5763 9.87819 14.7563C9.63123 14.8688 9.40613 15.0939 8.95592 15.5441L6 18.5M6 32H9C9.82843 32 10.5 31.3284 10.5 30.5V26C10.5 25.1716 9.82843 24.5 9 24.5H6C5.17157 24.5 4.5 25.1716 4.5 26V30.5C4.5 31.3284 5.17157 32 6 32ZM16.5 32H19.5C20.3284 32 21 31.3284 21 30.5V21.5C21 20.6716 20.3284 20 19.5 20H16.5C15.6716 20 15 20.6716 15 21.5V30.5C15 31.3284 15.6716 32 16.5 32ZM27 32H30C30.8284 32 31.5 31.3284 31.5 30.5V15.5C31.5 14.6716 30.8284 14 30 14H27C26.1716 14 25.5 14.6716 25.5 15.5V30.5C25.5 31.3284 26.1716 32 27 32Z" stroke="#4F46E5" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </button>
                                        <p class="mt-2 w-[92px] text-center font-medium text-sm text-black"> 15,000+ </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="mx-auto max-w-2xl px-1 py-1 sm:px-6 sm:py-2 lg:max-w-7xl lg:px-8 mt-1 bg-neutral-50"
            // style={{ backgroundImage: `url(${BackgroundFrame})`, backgroundRepeat: "none", backgroundSize: "contain" }}
            >
                {dialog ? <ProductDialog open={dialog} onOpen={() => onOpen} cardProduct={cardProduct} onAddToCard={onAddToCard} setDialog={setDialog} /> : ""}
                {openshopping ? <ShoppingCard openshopping={openshopping} setOpenShopping={setOpenShopping} onRemove={onRemove} onCount={onCount} onOpen={onOpen} /> : ""}
                {/* banner */}

                {
                    TrendingProducts ? TrendingProducts.section.map((specialProd, i) => {
                        let [keys] = Object.keys(specialProd)
                        let arr = specialProd[keys]
                        // setProdkeys(arr)
                        // console.log(arr)
                        return (<div className="mt-6  grid grid-cols-2  sm:grid-cols-2 lg:grid-cols-4 md:grid-cols-3 xl:gap-x-8">
                            {/* <h4 class="text-2xl font-bold tracking-tight text-green-900">{keys}</h4> */}
                            {arr.map((val, id) => {

                                return (
                                    <div key={val.uni_productId} className={val.freez ? "group relative cursor-not-allowed transformed opacity-40 bg-white" : "group relative bg-white drop-shadow-lg m-1"} onClick={() => onOpen(val)}>

                                        <div className="relative aspect-h-1 aspect-w-1 w-full overflow-hidden bg-white lg:aspect-none group-hover:opacity-75 lg:h-48">
                                            <a class="relative mx-3 mt-3 flex h-60 overflow-hidden " href="#">
                                                <img
                                                    src={val.url}
                                                    style={{ height: "220px", width: "100%", objectFit: "fill", aspectRatio: "auto" }}
                                                    loading="lazy"
                                                    alt={val.type}
                                                    className="h-full w-full aspect-ratio-auto object-cover object-center lg:h-full lg:w-full"
                                                    title={val.product_name}
                                                />
                                                <span class="absolute top-0 left-0 m-1 bg-[#E74040] px-2 text-center text-sm font-medium text-white">{val.offer ? val.offer : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
                                                    <path d="M2 6.342a3.375 3.375 0 0 1 6-2.088 3.375 3.375 0 0 1 5.997 2.26c-.063 2.134-1.618 3.76-2.955 4.784a14.437 14.437 0 0 1-2.676 1.61c-.02.01-.038.017-.05.022l-.014.006-.004.002h-.002a.75.75 0 0 1-.592.001h-.002l-.004-.003-.015-.006a5.528 5.528 0 0 1-.232-.107 14.395 14.395 0 0 1-2.535-1.557C3.564 10.22 1.999 8.558 1.999 6.38L2 6.342Z" />
                                                </svg>
                                                }</span>
                                            </a>

                                            {val.freez ? <span className="absolute flex text-md text-amber-400 bottom-0 left-20 -translate-x-1/2 bg-red-600   inline-block">
                                                <span className="relative text-white">Sold Out</span>
                                            </span> : <span className="absolute h-5 flex text-md text-amber-400 bottom-0 left-28 -translate-x-2/3 bg-red-600 inline-block">
                                                <span className="flex items-center justify-center text-white text-sm  bg-black w-20"> {Math.floor(Math.abs(((val.offer_prize - val.real_prize) / val.real_prize) * 100))}  <span className="text-sm items-center justify-center">% off</span></span>
                                                <div class="flex items-center justify-center">
                                                    <svg class="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                                    </svg>
                                                    <p class="mr-3 text-sm font-bold text-white dark:text-white">{val.rating}</p>
                                                </div>
                                                {val.delivery_charge == "00" ? <div class="flex items-center justify-center">
                                                    <img width="48" height="48" src="https://img.icons8.com/external-flat-gradient-andi-nur-abdillah/64/external-delivery-delivery-and-logistic-flat-gradient-flat-gradient-andi-nur-abdillah-2.png" alt=" external-delivery-delivery-and-logistic-flat-gradient-flat-gradient-andi-nur-abdillah-2" className='animate-bounce' />
                                                    <p class="mr-4 text-sm font-bold text-white dark:text-white">Free</p>
                                                </div> : ""}
                                            </span>

                                            }

                                        </div>
                                        <div className="grid grid-cols-1 justify-between">
                                            <div className="h-20 p-1 justify-between">
                                                <p className="capitalize text-sm line-clamp-2 text-gray"><span>
                                                    {val.product_name}
                                                </span> </p>
                                                <p className="capitalize text-xs line-clamp-2 text-sky-600"><span>
                                                    {val.product_detail}
                                                </span> </p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2 justify-between">
                                            <div className="p-1 flex justify-between">
                                                <h3 className="capitalize truncate text-xs text-gray-700">
                                                    <span aria-hidden="true" className="absolute inset-0" />
                                                    {val.color}
                                                </h3>
                                                {/* <p rel="schema:name" className="mt-1 text-xs text-gray-500"><span property="schema:name">{val.type}</span> {val.color}</p> */}
                                            </div>
                                            <div className="p-1 justify-between">
                                                <p className="text-xs font-medium text-green">₹<span> {val.offer_prize}</span>/-<span className="text-xs  text-gray-700 line-through"> {val.real_prize}  </span></p>

                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="grid grid-cols-2 justify-between">
                                            <div className="p-1 justify-between">
                                                <p className="capitalize truncate text-xs flex text-gray-500"><span>
                                                    {/* <a href={`/product/${navItem}/${val.type} ${val.color}`} onClick={(e) => e.preventDefault()}> */}
                                                    {val.type}
                                                    {/* </a> */}
                                                </span> </p>
                                            </div>
                                            <div className="p-1 flex justify-between">
                                                <p className="capitalize truncate text-xs flex text-gray-700">
                                                    {/* <span aria-hidden="true" className="absolute inset-0" /> */}
                                                    <span> {val.brand}</span>
                                                </p>
                                                {/* <p rel="schema:name" className="mt-1 text-sm text-gray-500"><span property="schema:name">{val.type}</span> {val.color}</p> */}
                                            </div>
                                        </div>
                                        <div className=" grid grid-cols-1 justify-between" >

                                            {!val.freez ? <div className="relative p-1 flex tems-center justify-center hover:scale-125  transition" onClick={() => onAddToCard({
                                                name: val.brand,
                                                product_name: val.product_name,
                                                price: val.offer_prize,
                                                real_prize: val.real_prize,
                                                id: val.id,
                                                freez: true,
                                                count: val.count,
                                                rating: 3.9,
                                                reviewCount: 117,
                                                imageSrc: val.url,
                                                imageAlt: val.brand,
                                                colors: { name: val.color, class: 'bg-gray-900', selectedClass: 'ring-gray-900' },
                                                sizes: { name: val.size[0], inStock: true },
                                                size: val.size,
                                                uni_productId: val.uni_productId,
                                                type: val.type,
                                                detail: val.product_name + val.product_detail,
                                                detail_url: val.detail_url,
                                                delivery_charge: val.delivery_charge
                                            })}>
                                                <a
                                                    href="#"
                                                    className="flex w-3/4 item-center justify-center rounded-md border border border-indigo-300 bg-black text-white px-1 py-1 text-base font-small shadow-sm hover:bg-indigo-700"
                                                >
                                                    Add To Cart 🛒
                                                </a>

                                            </div> : <div className="relative p-1 flex tems-center justify-center">
                                                <a
                                                    href="#"
                                                    className="flex w-3/4 item-center justify-center rounded-md border border border-indigo-300 bg-red-600 text-white px-1 py-1 text-base font-small shadow-sm hover:bg-indigo-700"
                                                >
                                                    Not Available
                                                </a>

                                            </div>}
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                        )
                    })
                        : <div role="status" class="max-w-sm p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700">
                            <div class="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                                <svg class="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
                                    <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                                </svg>
                            </div>
                            <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                            <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                            <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                            <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                            <div class="flex items-center mt-4">
                                <svg class="w-10 h-10 me-3 text-gray-200 dark:text-gray-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                                </svg>
                                <div>
                                    <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                                    <div class="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                                </div>
                            </div>
                            <span class="sr-only">Loading...</span>
                        </div>}

            </div >


            <div className="w-full">
                <section id="bottom-navigation" className="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow">
                    {/* <section id="bottom-navigation" className="block fixed inset-x-0 bottom-0 z-10 bg-white shadow"> */}
                    <div id="tabs" className="flex justify-between">
                        <a href="/product/printed" className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
                            <svg width="25" height="25" viewBox="0 0 42 42" className="inline-block mb-1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M21.0847458,3.38674884 C17.8305085,7.08474576 17.8305085,10.7827427 21.0847458,14.4807396 C24.3389831,18.1787365 24.3389831,22.5701079 21.0847458,27.6548536 L21.0847458,42 L8.06779661,41.3066256 L6,38.5331279 L6,26.2681048 L6,17.2542373 L8.88135593,12.4006163 L21.0847458,2 L21.0847458,3.38674884 Z" fill="currentColor" fill-opacity="0.1"></path>
                                    <path d="M11,8 L33,8 L11,8 Z M39,17 L39,36 C39,39.3137085 36.3137085,42 33,42 L11,42 C7.6862915,42 5,39.3137085 5,36 L5,17 L7,17 L7,36 C7,38.209139 8.790861,40 11,40 L33,40 C35.209139,40 37,38.209139 37,36 L37,17 L39,17 Z" fill="currentColor"></path>
                                    <path d="M22,27 C25.3137085,27 28,29.6862915 28,33 L28,41 L16,41 L16,33 C16,29.6862915 18.6862915,27 22,27 Z" stroke="currentColor" stroke-width="2" fill="currentColor" fill-opacity="0.1"></path>
                                    <rect fill="currentColor" transform="translate(32.000000, 11.313708) scale(-1, 1) rotate(-45.000000) translate(-32.000000, -11.313708) " x="17" y="10.3137085" width="30" height="2" rx="1"></rect>
                                    <rect fill="currentColor" transform="translate(12.000000, 11.313708) rotate(-45.000000) translate(-12.000000, -11.313708) " x="-3" y="10.3137085" width="30" height="2" rx="1"></rect>
                                </g>
                            </svg>
                            <span class="sr-only"><a href="https://www.wikidata.org/wiki/Q13464614">Printed Mug, Coffee Mugs</a>, Panda Printed Mug Call Experience the joy of printo art every morning with our amazing Artistic Expression.Our Amazon bulk Printed Mugs, Ceramic Items a beautiful piece of artwork that wraps around its exterior, turning an everyday item into a piece of art.
                                Save your amazing power looks items and flip to cart now our brands and products -Triokzz, JiGari, Ted baker, fasion, kaylazzk, unique art print mugs.
                            </span>

                            <span className="tab tab-home block text-xs">Printed Hub</span>
                        </a>
                        <a href="/product/womens" className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
                            <svg width="25" height="25" viewBox="0 0 42 42" className="inline-block mb-1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M14.7118754,20.0876892 L8.03575361,20.0876892 C5.82661462,20.0876892 4.03575361,18.2968282 4.03575361,16.0876892 L4.03575361,12.031922 C4.03575361,8.1480343 6.79157254,4.90780265 10.4544842,4.15995321 C8.87553278,8.5612583 8.1226025,14.3600511 10.9452499,15.5413938 C13.710306,16.6986332 14.5947501,18.3118357 14.7118754,20.0876892 Z M14.2420017,23.8186831 C13.515543,27.1052019 12.7414284,30.2811559 18.0438552,31.7330419 L18.0438552,33.4450645 C18.0438552,35.6542035 16.2529942,37.4450645 14.0438552,37.4450645 L9.90612103,37.4450645 C6.14196811,37.4450645 3.09051926,34.3936157 3.09051926,30.6294627 L3.09051926,27.813861 C3.09051926,25.604722 4.88138026,23.813861 7.09051926,23.813861 L14.0438552,23.813861 C14.1102948,23.813861 14.1763561,23.8154808 14.2420017,23.8186831 Z M20.7553776,32.160536 C23.9336213,32.1190063 23.9061943,29.4103976 33.8698747,31.1666916 C34.7935223,31.3295026 35.9925894,31.0627305 37.3154077,30.4407183 C37.09778,34.8980343 33.4149547,38.4450645 28.9036761,38.4450645 C24.9909035,38.4450645 21.701346,35.7767637 20.7553776,32.160536 Z" fill="currentColor" opacity="0.1"></path>
                                    <g transform="translate(2.000000, 3.000000)">
                                        <path d="M8.5,1 C4.35786438,1 1,4.35786438 1,8.5 L1,13 C1,14.6568542 2.34314575,16 4,16 L13,16 C14.6568542,16 16,14.6568542 16,13 L16,4 C16,2.34314575 14.6568542,1 13,1 L8.5,1 Z" stroke="currentColor" stroke-width="2"></path>
                                        <path d="M4,20 C2.34314575,20 1,21.3431458 1,23 L1,27.5 C1,31.6421356 4.35786438,35 8.5,35 L13,35 C14.6568542,35 16,33.6568542 16,32 L16,23 C16,21.3431458 14.6568542,20 13,20 L4,20 Z" stroke="currentColor" stroke-width="2"></path>
                                        <path d="M23,1 C21.3431458,1 20,2.34314575 20,4 L20,13 C20,14.6568542 21.3431458,16 23,16 L32,16 C33.6568542,16 35,14.6568542 35,13 L35,8.5 C35,4.35786438 31.6421356,1 27.5,1 L23,1 Z" stroke="currentColor" stroke-width="2"></path>
                                        <path d="M34.5825451,33.4769886 L38.3146092,33.4322291 C38.8602707,33.4256848 39.3079219,33.8627257 39.3144662,34.4083873 C39.3145136,34.4123369 39.3145372,34.4162868 39.3145372,34.4202367 L39.3145372,34.432158 C39.3145372,34.9797651 38.8740974,35.425519 38.3265296,35.4320861 L34.5944655,35.4768456 C34.048804,35.4833899 33.6011528,35.046349 33.5946085,34.5006874 C33.5945611,34.4967378 33.5945375,34.4927879 33.5945375,34.488838 L33.5945375,34.4769167 C33.5945375,33.9293096 34.0349773,33.4835557 34.5825451,33.4769886 Z" fill="currentColor" transform="translate(36.454537, 34.454537) rotate(-315.000000) translate(-36.454537, -34.454537) "></path>
                                        <circle stroke="currentColor" stroke-width="2" cx="27.5" cy="27.5" r="7.5"></circle>
                                    </g>
                                </g>
                            </svg>
                            <span class="sr-only"><a href="https://www.wikidata.org/wiki/Q131151" >Buy Womens Solid Tshirt</a>. Experience ultimate comfort and style with our Tshirt. Comfort Unisex Plain T-Shirt. Save your amazing power looks items and flip to cart now our brands and products -Triokzz, JiGari, Ted baker, fasion, kaylazzk, unique art print mugs, Bewakoofy items, home decor items, sculptures, inspiring, large luggage bags, popular duffle, touring bags, big size travel bags online, purse, wholesale mugs, custom cups and mugs, ceramic printed mugs, copper drinking mugs, cups for camping, purses for women, purse sale, branded bags for women, multi pocket bags</span>
                            <span className="tab tab-kategori block text-xs">Womens/Mens</span>
                        </a>
                        <a href="/product/travelling" className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
                            <svg width="25" height="25" viewBox="0 0 42 42" className="inline-block mb-1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M20.5890101,0.254646884 C12.8696785,5.50211755 8.0025785,14.258415 14.1941217,18.8708225 C23.16683,25.5550669 13.3362326,40.2698884 33.1021758,38.4149164 C29.6814884,40.8311956 25.5065164,42.2507054 21,42.2507054 C9.40202025,42.2507054 0,32.8486852 0,21.2507054 C0,9.79003409 9.18071714,0.473634138 20.5890101,0.254646884 Z" fill="currentColor" opacity="0.1"></path>
                                    <path d="M25.9500282,20.3643496 L22.4308312,38.2677802 C22.3775703,38.5387376 22.1147395,38.7152155 21.8437821,38.6619546 C21.6570955,38.6252584 21.507413,38.4857901 21.4576354,38.3021581 L16.5951895,20.3643496 L20.099732,4.44663907 C20.1385204,4.27046145 20.2692032,4.12883813 20.4417012,4.07604096 C20.7057521,3.99522179 20.9853245,4.14376046 21.0661436,4.40781135 L25.9500282,20.3643496 Z M21.3022963,22.2852638 C22.4068658,22.2852638 23.3022963,21.3898333 23.3022963,20.2852638 C23.3022963,19.1806943 22.4068658,18.2852638 21.3022963,18.2852638 C20.1977268,18.2852638 19.3022963,19.1806943 19.3022963,20.2852638 C19.3022963,21.3898333 20.1977268,22.2852638 21.3022963,22.2852638 Z" fill="currentColor" transform="translate(21.272609, 20.629524) rotate(-315.000000) translate(-21.272609, -20.629524) "></path>
                                    <circle stroke="currentColor" stroke-width="2" cx="21" cy="21" r="20"></circle>
                                </g>
                            </svg>
                            <span class="sr-only">Meet your perfect travel <a href="https://www.wikidata.org/wiki/Q467505">bags, purse, handbags</a> companion meesh with ted beker, the Globetrotter’s Companion Travel Purse. Designed with the modern traveler in mind, this compact purse keeps your essentials organized and secure, whether you’re exploring a new city or running errands in your hometown.sculptures, inspiring, large luggage bags, popular duffle, touring bags, big size travel bags online, purse, wholesale mugs, custom cups and mugs, ceramic printed mugs, copper drinking mugs, cups for camping, purses for women, purse sale, branded bags for women, multi pocket bags</span>

                            <span className="tab tab-explore block text-xs">Explore Travel</span>
                        </a>
                        <a href="#" onClick={() => onshoppingRender()} className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
                            <svg width="25" height="25" viewBox="0 0 42 42" className="inline-block mb-1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M14.7118754,20.0876892 L8.03575361,20.0876892 C5.82661462,20.0876892 4.03575361,18.2968282 4.03575361,16.0876892 L4.03575361,12.031922 C4.03575361,8.1480343 6.79157254,4.90780265 10.4544842,4.15995321 C8.87553278,8.5612583 8.1226025,14.3600511 10.9452499,15.5413938 C13.710306,16.6986332 14.5947501,18.3118357 14.7118754,20.0876892 Z M14.2420017,23.8186831 C13.515543,27.1052019 12.7414284,30.2811559 18.0438552,31.7330419 L18.0438552,33.4450645 C18.0438552,35.6542035 16.2529942,37.4450645 14.0438552,37.4450645 L9.90612103,37.4450645 C6.14196811,37.4450645 3.09051926,34.3936157 3.09051926,30.6294627 L3.09051926,27.813861 C3.09051926,25.604722 4.88138026,23.813861 7.09051926,23.813861 L14.0438552,23.813861 C14.1102948,23.813861 14.1763561,23.8154808 14.2420017,23.8186831 Z M20.7553776,32.160536 C23.9336213,32.1190063 23.9061943,29.4103976 33.8698747,31.1666916 C34.7935223,31.3295026 35.9925894,31.0627305 37.3154077,30.4407183 C37.09778,34.8980343 33.4149547,38.4450645 28.9036761,38.4450645 C24.9909035,38.4450645 21.701346,35.7767637 20.7553776,32.160536 Z" fill="currentColor" opacity="0.1"></path>
                                    <g transform="translate(2.000000, 3.000000)">
                                        <path d="M8.5,1 C4.35786438,1 1,4.35786438 1,8.5 L1,13 C1,14.6568542 2.34314575,16 4,16 L13,16 C14.6568542,16 16,14.6568542 16,13 L16,4 C16,2.34314575 14.6568542,1 13,1 L8.5,1 Z" stroke="currentColor" stroke-width="2"></path>
                                        <path d="M4,20 C2.34314575,20 1,21.3431458 1,23 L1,27.5 C1,31.6421356 4.35786438,35 8.5,35 L13,35 C14.6568542,35 16,33.6568542 16,32 L16,23 C16,21.3431458 14.6568542,20 13,20 L4,20 Z" stroke="currentColor" stroke-width="2"></path>
                                        <path d="M23,1 C21.3431458,1 20,2.34314575 20,4 L20,13 C20,14.6568542 21.3431458,16 23,16 L32,16 C33.6568542,16 35,14.6568542 35,13 L35,8.5 C35,4.35786438 31.6421356,1 27.5,1 L23,1 Z" stroke="currentColor" stroke-width="2"></path>
                                        <path d="M34.5825451,33.4769886 L38.3146092,33.4322291 C38.8602707,33.4256848 39.3079219,33.8627257 39.3144662,34.4083873 C39.3145136,34.4123369 39.3145372,34.4162868 39.3145372,34.4202367 L39.3145372,34.432158 C39.3145372,34.9797651 38.8740974,35.425519 38.3265296,35.4320861 L34.5944655,35.4768456 C34.048804,35.4833899 33.6011528,35.046349 33.5946085,34.5006874 C33.5945611,34.4967378 33.5945375,34.4927879 33.5945375,34.488838 L33.5945375,34.4769167 C33.5945375,33.9293096 34.0349773,33.4835557 34.5825451,33.4769886 Z" fill="currentColor" transform="translate(36.454537, 34.454537) rotate(-315.000000) translate(-36.454537, -34.454537) "></path>
                                        <circle stroke="currentColor" stroke-width="2" cx="27.5" cy="27.5" r="7.5"></circle>
                                    </g>
                                </g>
                            </svg>
                            <span class="sr-only">Save your amazing power looks items and flip to cart now</span>

                            <span className="tab tab-whishlist block text-xs">Wishlist</span>
                        </a>

                    </div>
                </section>
            </div>
            <footer className=" relative 
             inset-x-0 bottom-0 bg-gray-900 rounded-lg shadow dark:bg-gray-900 m-2">
                <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                    <div className="sm:flex sm:items-center sm:justify-between">
                        <a href="#" className="flex items-center mb-4 sm:mb-0">
                            <img src={Logo} className="h-14 w-25 mr-3" alt="shoppp Logo" title="triokzz" />
                            <span class="sr-only">Save your amazing power looks items and flip to cart now our brands and products -Triokzz, JiGari, Ted baker, fasion, kaylazzk, unique art print mugs, Bewakoofy items, home decor items, sculptures, inspiring, large luggage bags, popular duffle, touring bags, big size travel bags online, purse, wholesale mugs, custom cups and mugs, ceramic printed mugs, copper drinking mugs, cups for camping, purses for women, purse sale, branded bags for women, multi pocket bags</span>
                        </a>
                        <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-white sm:mb-0 dark:text-white">
                            <li className="mr-4 hover:underline md:mr-6 ">
                                <a href="/product/womens"> Womens</a>
                            </li>
                            <li className="mr-4 hover:underline md:mr-6 ">
                                <a href="/watchtv"> WatchTv</a>
                            </li>
                            <li className="mr-4 hover:underline md:mr-6 ">
                                <a href="/product/mens">Mens</a>
                            </li>
                            <li className="mr-4 hover:underline md:mr-6 ">
                                <a href="/product/Home%20Decor%20&%20Temple">Home and Temple Decor</a>
                            </li>
                            <li className="mr-4 hover:underline md:mr-6 " onClick={() => navigate("/about")}>
                                About Us
                            </li>
                            <li className="mr-4 hover:underline md:mr-6" onClick={() => navigate("/privacy")}>
                                Privacy
                            </li>
                            <li className="mr-4 hover:underline md:mr-6 " onClick={() => navigate("/contact")}>
                                Contact Us
                            </li>
                        </ul>
                    </div>
                    <div class="sm:flex sm:items-center sm:justify-between">
                        <a href="#" class="flex items-center mb-4 ml-4 sm:mb-0 text-white">
                            Products
                        </a>
                        <ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-white sm:mb-0 dark:text-white">
                            <li class="mr-4 hover:underline md:mr-6 " >
                                <a href="/product/womens">Unisex Tshirt </a>
                            </li>
                            <li class="mr-4 hover:underline md:mr-6 " >
                                <a href="/product/mens">Solid Tshirt</a>
                            </li>
                            <li class="mr-4 hover:underline md:mr-6">
                                <a href="/product/travelling">Travel Bags</a>
                            </li>
                            <li class="mr-4 hover:underline md:mr-6">
                                <a href="/product/printed">Printed Mugs</a>
                            </li>
                            <li class="mr-4 hover:underline md:mr-6 ">
                                <a href="/contact" class="hover:underline">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                    <div class="sm:flex sm:items-center sm:justify-between">
                        <a href="#" class="flex items-center mb-4 ml-4 sm:mb-0 text-white">
                            Available Color
                        </a>
                        {
                            TrendingProducts && TrendingProducts.section.map((specialProd, i) => {
                                let [keys] = Object.keys(specialProd)
                                let arr = specialProd[keys]
                                // setProdkeys(arr)
                                // console.log(arr)
                                return (<ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-white sm:mb-0 dark:text-white">
                                    {arr.map((val, id) => {
                                        let somthing = addtocard && addtocard.find((values) => {

                                            return values.id == val.id
                                        })
                                        if (!val.freez && somthing == undefined) {
                                            return (<li class="mr-4 hover:underline md:mr-6 ">
                                                <a href={`/amazing+product/${navItem}/${val.type}+${val.id}`} >{val.color} {val.type}</a>
                                                <span class="sr-only">{val.product_detail}</span>
                                            </li>)
                                        }
                                    })
                                    }
                                </ul>)
                            }
                            )
                        }

                    </div>
                    <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />

                    <div class="sm:flex sm:items-center sm:justify-between">
                        <span className="block text-sm text-white sm:text-center dark:text-gray-400">© 2023 Developed By <a href="https://www.triokzz.com/contact" className="hover:underline">Triokzz</a>. All Rights Reserved.</span>
                        <div class="flex mt-4 sm:justify-center sm:mt-0">
                            <a href="https://www.facebook.com/profile.php?id=61553460826080&mibexid=ZbWKwL" class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                                    <path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd" />
                                </svg>
                                <span class="sr-only">Experience the joy of art every morning with our Artistic Expression. A beautiful piece of artwork that wraps around its exterior.</span>
                            </a>
                            <a href="https://www.instagram.com/triokzz?igsh=MWUzM3dweTNyY2NpeA==" class="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5">
                                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 21 16">
                                    <path d="M16.942 1.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.585 11.585 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3A17.392 17.392 0 0 0 .182 13.218a15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.63 10.63 0 0 1-1.706-.83c.143-.106.283-.217.418-.33a11.664 11.664 0 0 0 10.118 0c.137.113.277.224.418.33-.544.328-1.116.606-1.71.832a12.52 12.52 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM6.678 10.813a1.941 1.941 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z" />
                                </svg>
                                <span class="sr-only">Follow Our Instagram Page And Get More exiting offers and coupan code</span>
                            </a>
                            <a href="#" class="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5">
                                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 17">
                                    <path fill-rule="evenodd" d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z" clip-rule="evenodd" />
                                </svg>
                                <span class="sr-only">Twitter page</span>
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </div >

    )
}

export default ProductList;